import { plainToClass } from 'class-transformer';
import { Module } from 'vuex';
import { User } from '@/shared/models/User';
import { actions } from '@/store/auth/actions';
import { getters } from '@/store/auth/getters';
import { mutations } from '@/store/auth/mutations';
import { AuthState, RootState } from '@/store/types';

export const state: AuthState = {
    user: plainToClass(User, JSON.parse(localStorage.getItem('auth-user') || '{}')) || undefined,
    token: localStorage.getItem('user-token') || '',
    status: '',
};

const namespaced = false;

export const auth: Module<AuthState, RootState> = {
    namespaced,
    state,
    getters,
    actions,
    mutations,
};
