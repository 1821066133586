import { createElementVNode as _createElementVNode, resolveComponent as _resolveComponent, createVNode as _createVNode, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = { class: "container-fluid background-red" }
const _hoisted_2 = { class: "row" }
const _hoisted_3 = { class: "footer" }
const _hoisted_4 = { class: "row info-row" }
const _hoisted_5 = { class: "col" }
const _hoisted_6 = { class: "col" }
const _hoisted_7 = { class: "col" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_Button = _resolveComponent("Button")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createElementVNode("div", _hoisted_2, [
      _createElementVNode("div", _hoisted_3, [
        _cache[3] || (_cache[3] = _createElementVNode("ul", { class: "disclaimer text-center" }, [
          _createElementVNode("li", null, [
            _createElementVNode("a", {
              href: "https://www.kwastwijnkopers.nl/contact/disclaimer/",
              target: "_blank"
            }, " Disclaimer ")
          ]),
          _createElementVNode("li", null, [
            _createElementVNode("a", {
              href: "https://www.kwastwijnkopers.nl/contact/algemene-voorwaarden/",
              target: "_blank"
            }, " Algemene voorwaarden ")
          ]),
          _createElementVNode("li", null, [
            _createElementVNode("a", {
              href: "https://www.kwastwijnkopers.nl/contact/privacy-statement/",
              target: "_blank"
            }, " Privacy statement ")
          ])
        ], -1)),
        _createElementVNode("div", _hoisted_4, [
          _createElementVNode("div", _hoisted_5, [
            _createElementVNode("img", {
              src: "/images/logo_white.svg",
              class: "clickable",
              onClick: _cache[0] || (_cache[0] = 
//@ts-ignore
(...args) => ($setup.navigateHome && $setup.navigateHome(...args)))
            })
          ]),
          _createElementVNode("div", _hoisted_6, [
            _cache[1] || (_cache[1] = _createElementVNode("ul", { class: "info" }, [
              _createElementVNode("li", { class: "list-title" }, " Kwast wijnkopers "),
              _createElementVNode("li", null, "Roggestraat 30"),
              _createElementVNode("li", null, "2153 GC Nieuw Vennep"),
              _createElementVNode("li", null, "0252 68 67 41")
            ], -1)),
            _createVNode(_component_Button, {
              type: "button",
              text: "Route",
              design: "small",
              onButtonClick: $setup.openMapsUrl
            }, null, 8, ["onButtonClick"])
          ]),
          _createElementVNode("div", _hoisted_7, [
            _cache[2] || (_cache[2] = _createElementVNode("ul", { class: "info" }, [
              _createElementVNode("li", { class: "list-title" }, " Vragen? "),
              _createElementVNode("li", null, "Wij staan altijd"),
              _createElementVNode("li", null, "klaar om u verder"),
              _createElementVNode("li", null, "te helpen")
            ], -1)),
            _createVNode(_component_Button, {
              class: "button-small",
              type: "button",
              text: "Contact",
              design: "small",
              onButtonClick: $setup.openContactUrl
            }, null, 8, ["onButtonClick"])
          ])
        ])
      ])
    ])
  ]))
}