import { ActionTree } from 'vuex';
import { ModalState, RootState } from '@/store/types';

export const actions: ActionTree<ModalState, RootState> = {
    MODAL_SHOW({ commit }, type) {
        commit('MODAL_SHOW', type);
    },
    MODAL_HIDE({ commit }) {
        commit('MODAL_HIDE');
    },
    DO_SEARCH({ commit }) {
        commit('DO_SEARCH');
    },
    SET_SEARCH({ commit }, data) {
        commit('SET_SEARCH', data);
    },
};
