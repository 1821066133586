
import { ref } from 'vue';
import AccountIcon from '@/assets/icons/account.svg';
import ChevronDown from '@/assets/icons/chevron-down.svg';
import ChevronUp from '@/assets/icons/chevron-up.svg';
import HeartIcon from '@/assets/icons/heart-outline.svg';
import InvoicesIcon from '@/assets/icons/invoices.svg';
import LogoutIcon from '@/assets/icons/logout.svg';
import OrderedBeforeIcon from '@/assets/icons/ordered-before.svg';
import OrdersIcon from '@/assets/icons/orders.svg';
import ShoppingCartIcon from '@/assets/icons/winkelmand_empty.svg';
import ShoppingCartFilledIcon from '@/assets/icons/winkelmand_filled.svg';

export default {
    props: {
        label: {
            type: String,
        },
        img: {
            type: String,
        },
        menu: {
            type: Array,
        },
        menuType: {
            type: String,
            default: 'small',
        },
        styling: {
            type: String,
        },
        design: {
            type: String,
        },
        count: {
            type: Number,
            required: false,
        },
        active: {
            type: Boolean,
            default: false,
        },
        routeName: {
            type: String,
            default: '',
        },
    },
    setup(props: any, context: any) {
        const dropDownType = ref(props.menuType);

        const icons = [
            {
                name: 'account',
                component: AccountIcon,
            },
            {
                name: 'winkelmand_empty',
                component: ShoppingCartIcon,
            },
            {
                name: 'winkelmand_filled',
                component: ShoppingCartFilledIcon,
            },
            {
                name: 'heart-outline',
                component: HeartIcon,
            },
            {
                name: 'ordered-before',
                component: OrderedBeforeIcon,
            },
            {
                name: 'invoices',
                component: InvoicesIcon,
            },
            {
                name: 'logout',
                component: LogoutIcon,
            },
            {
                name: 'orders',
                component: OrdersIcon,
            },
        ];

        function getIconByName(name: string) {
            return icons.find((icon: any) => icon.name === name)?.component;
        }

        function menuItemClicked(menuItem: any) {
            context.emit('menuItemClicked', menuItem);
        }

        function getButtonStyling() {
            const styleObj: any = {};

            if (dropDownType.value === 'small') {
                styleObj.relative = true;
            }

            if (props.design) {
                styleObj[props.design] = true;
            }

            return styleObj;
        }

        const show = ref(false);

        function navClick() {
            show.value = !show.value;
            context.emit('navClick');
        }

        function hideNav() {
            show.value = false;
        }

        return {
            navClick,
            hideNav,
            show,
            menuItemClicked,
            getButtonStyling,
            getIconByName,
            ChevronDown,
            ChevronUp,
        };
    },
};
