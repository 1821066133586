
import { ref } from 'vue';
import ChevronForward from '@/assets/icons/chevron-forward.svg';

export default {
    props: {
        label: {
            type: String,
        },
        logo: {
            type: String,
        },
        menu: {
            type: Array,
        },
        menuType: {
            type: String,
            default: 'small',
        },
        styling: {
            type: String,
        },
        design: {
            type: String,
        },
    },
    setup(props: any, context: any) {
        const style = ref(props.styling);
        const dropDownType = ref(props.menuType);
        const show = ref(false);

        function menuItemClicked(menuItem: any) {
            context.emit('menuItemClicked', menuItem);
        }

        function getButtonStyling() {
            const styleObj: any = {};
            if (style.value) {
                styleObj.red = true;
            }

            if (dropDownType.value === 'small') {
                styleObj.relative = true;
            }

            if (props.design) {
                styleObj[props.design] = true;
            }

            if (show.value) {
                styleObj.hidden = true;
            }

            return styleObj;
        }

        function navClick() {
            show.value = !show.value;
            context.emit('navClick');
        }

        function hideNav() {
            show.value = false;
            context.emit('hideNav');
        }

        return {
            navClick,
            hideNav,
            show,
            menuItemClicked,
            getButtonStyling,
            ChevronForward,
        };
    },
};
