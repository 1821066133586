import axios from 'axios';
import { ActionTree } from 'vuex';
import { Action } from '@/shared/models/Action';
import { Wine } from '@/shared/models/Wine';
import { WineState, RootState } from '@/store/types';

export const actions: ActionTree<WineState, RootState> = {
    WINES_GET({ commit, state }, { currentPage, search }) {
        commit('LOADING_START');
        let getWinesURL = `${process.env.VUE_APP_API_URL}bestelsite/assortment?page=${currentPage.value}&${state.assortmentFilter}&${state.order}`;
        if (search) {
            getWinesURL += `&search=${search}`;
        }

        return new Promise((resolve, reject) => {
            axios.get(getWinesURL)
                .then((resp: any) => {
                    commit('LOADING_STOP');

                    resolve(resp);
                })
                .catch((err) => {
                    commit('LOADING_STOP');

                    reject(err);
                });
        });
    },
    WINES_GET_ALL({ commit, state }) {
        commit('LOADING_START');
        const getWinesURL = `${process.env.VUE_APP_API_URL}bestelsite/assortment?page=1`;

        return new Promise((resolve, reject) => {
            axios.get(getWinesURL)
                .then((resp: any) => {
                    commit('LOADING_STOP');

                    resolve(resp);
                })
                .catch((err) => {
                    commit('LOADING_STOP');

                    reject(err);
                });
        });
    },
    ACTIONS_GET({ commit }) {
        commit('LOADING_START');
        return new Promise((resolve, reject) => {
            axios.get(`${process.env.VUE_APP_API_URL}bestelsite/discounts`)
                .then((resp: any) => {
                    commit('LOADING_STOP');

                    resolve(resp);
                })
                .catch((err) => {
                    commit('LOADING_STOP');

                    reject(err);
                });
        });
    },
    ACTIONS_GET_MORE({ commit }, action: Action) {
        commit('LOADING_START');
        return new Promise((resolve, reject) => {
            axios.get(`${process.env.VUE_APP_API_URL}bestelsite/discounts/${action.id}/items?page=${action.page}`)
                .then((resp: any) => {
                    commit('LOADING_STOP');

                    resolve(resp);
                })
                .catch((err) => {
                    commit('LOADING_STOP');

                    reject(err);
                });
        });
    },
    WINES_GET_FAVORITES({ commit, state }, { currentPage }) {
        commit('LOADING_START');

        return new Promise((resolve, reject) => {
            axios.get(`${process.env.VUE_APP_API_URL}bestelsite/favorites?page=${currentPage.value}&${state.favoritesFilter}&${state.order}`)
                .then((resp: any) => {
                    commit('LOADING_STOP');

                    resolve(resp);
                })
                .catch((err) => {
                    commit('LOADING_STOP');

                    reject(err);
                });
        });
    },
    WINES_GET_ORDERED_BEFORE({ commit, state }, { currentPage }) {
        commit('LOADING_START');

        return new Promise((resolve, reject) => {
            axios.get(`${process.env.VUE_APP_API_URL}bestelsite/account/wines?page=${currentPage.value}&${state.filter}&${state.order}`)
                .then((resp: any) => {
                    commit('LOADING_STOP');

                    resolve(resp);
                })
                .catch((err) => {
                    commit('LOADING_STOP');

                    reject(err);
                });
        });
    },
    WINE_FAVORITE({ commit }, wine: Wine) {
        commit('LOADING_START');

        return new Promise((resolve, reject) => {
            axios.post(`${process.env.VUE_APP_API_URL}bestelsite/wines/${wine.id}/favorite`, {
                favorite: wine.is_favorited,
            })
                .then((resp: any) => {
                    commit('LOADING_STOP');

                    resolve(resp);
                })
                .catch((err) => {
                    commit('LOADING_STOP');

                    reject(err);
                });
        });
    },
    WINE_GET({ commit }, id) {
        commit('LOADING_START');

        return new Promise((resolve, reject) => {
            axios.get(`${process.env.VUE_APP_API_URL}bestelsite/wines/${id}`)
                .then((resp: any) => {
                    commit('LOADING_STOP');

                    resolve(resp);
                })
                .catch((err) => {
                    commit('LOADING_STOP');

                    reject(err);
                });
        });
    },
    ZALTO_GET({ commit }) {
        commit('LOADING_START');

        return new Promise((resolve, reject) => {
            axios.get(`${process.env.VUE_APP_API_URL}bestelsite/products/zalto`)
                .then((resp: any) => {
                    commit('LOADING_STOP');

                    resolve(resp);
                })
                .catch((err) => {
                    commit('LOADING_STOP');

                    reject(err);
                });
        });
    },
    ZALTO_SHOW({ commit }, id) {
        commit('LOADING_START');

        return new Promise((resolve, reject) => {
            axios.get(`${process.env.VUE_APP_API_URL}bestelsite/products/zalto/${id}`)
                .then((resp: any) => {
                    commit('LOADING_STOP');

                    resolve(resp);
                })
                .catch((err) => {
                    commit('LOADING_STOP');

                    reject(err);
                });
        });
    },
    STOPPERS_GET({ commit }) {
        commit('LOADING_START');

        return new Promise((resolve, reject) => {
            axios.get(`${process.env.VUE_APP_API_URL}bestelsite/products/stopper`)
                .then((resp: any) => {
                    commit('LOADING_STOP');

                    resolve(resp);
                })
                .catch((err) => {
                    commit('LOADING_STOP');

                    reject(err);
                });
        });
    },
    STOPPERS_SHOW({ commit }, id) {
        commit('LOADING_START');

        return new Promise((resolve, reject) => {
            axios.get(`${process.env.VUE_APP_API_URL}bestelsite/products/stopper/${id}`)
                .then((resp: any) => {
                    commit('LOADING_STOP');

                    resolve(resp);
                })
                .catch((err) => {
                    commit('LOADING_STOP');

                    reject(err);
                });
        });
    },
};
