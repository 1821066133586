import { GetterTree } from 'vuex';
import { ModalState, RootState } from '@/store/types';

export const getters: GetterTree<ModalState, RootState> = {
    showModal: (state) => (type: string) => state.show === type,
    showNavMobile: (state) => () => state.showNavMobile,
    showFilter: (state) => () => state.showFilter,
    isLoading: (state) => () => state.loading,
    isSearching: (state) => () => state.isSearching,
    getSearch: (state) => () => state.searchString,
};
