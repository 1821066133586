import axios from 'axios';
import router from '@/router';
import store from '@/store';

export default function setup() {
    axios.interceptors.request.use((config) => {
        const token = store.getters.authToken;

        if (process.env.VUE_APP_CLOUDFLARE_ID && process.env.VUE_APP_CLOUDFLARE_SECRET) {
            config.headers['CF-Access-Client-Id'] = process.env.VUE_APP_CLOUDFLARE_ID;
            config.headers['CF-Access-Client-Secret'] = process.env.VUE_APP_CLOUDFLARE_SECRET;
        }

        if (token) {
            config.headers.Authorization = `Bearer ${token}`;
        }

        config.headers['X-API-Key'] = 'bestelsite';
        return config;
    });

    axios.interceptors.response.use((res) => res, (err) => {
        if (err && err.response && err.response.status === 401) {
            store.dispatch('AUTH_LOGOUT').then(() => router.push({ name: 'Login' }));
        }

        return Promise.reject(err);
    });
}
