import { Transform } from 'class-transformer';
import moment from 'moment';

export class User {
    email!: string;
    password?: string;

    name!: string;

    firstname!: string;
    middlename?: string;
    lastname!: string;

    customer_no!: string;
    'customer-name'?: string;

    gender: 'male' | 'female' | 'other' = 'male';
    @Transform(({
        value, key, obj, type,
    }) => {
        if (type === 0) {
            const date = moment(value, 'YYYY-MM-DD');
            return {
                date: date.date(),
                month: date.month() + 1,
                year: date.year(),
            };
        }

        if (type === 1) {
            return moment().set({
                date: value.date,
                month: value.month - 1,
                year: value.year,
            }).format('YYYY-MM-DD');
        }

        return value;
    })
    dob?: BirthDate = {
        date: moment().date(),
        month: moment().month() + 1,
        year: moment().year(),
    }
}

export class BirthDate {
    date!: number;
    month!: number;
    year!: number;
}
