
import {
    computed, onMounted, onUnmounted, ref, 
} from 'vue';
import { useStore } from 'vuex';
import Footer from '@/components/Footer.vue';
import Navbar from '@/components/Navbar.vue';
import NavbarMobile from '@/components/NavbarMobile.vue';
import Modal from '@/views/Modal.vue';

export default {
    components: {
        Navbar,
        NavbarMobile,
        Footer,
        Modal,
    },
    setup() {
        const store = useStore();
        const isAuthenticated = computed(() => store.getters.isAuthenticated);
        const width = ref(window.innerWidth);
        const onWidthChange = () => width.value = window.innerWidth;
        const showNavMobile = computed(() => store.getters.showNavMobile());
        const showFilter = computed(() => store.getters.showFilter());
        const loading = computed(() => store.getters.isLoading());

        onMounted(() => {
            window.addEventListener('resize', onWidthChange);
        });

        onUnmounted(() => {
            window.removeEventListener('resize', onWidthChange);
        });

        if (store.getters.isAuthenticated) {
            store.dispatch('CART_COUNT');
        }

        return {
            isAuthenticated,
            width,
            showNavMobile,
            showFilter,
            loading,
        };
    },
};
