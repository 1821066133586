import { MutationTree } from 'vuex';
import { ModalState } from '../types';

export const mutations: MutationTree<ModalState> = {
    MODAL_SHOW: (state, type) => {
        state.show = type;
    },
    MODAL_HIDE(state) {
        state.show = '';
    },
    NAV_MOBILE_TOGGLE: (state) => {
        state.showNavMobile = !state.showNavMobile;
    },
    FILTER_TOGGLE: (state) => {
        state.showFilter = !state.showFilter;
    },
    LOADING_START: (state) => {
        state.loading = true;
    },
    LOADING_STOP: (state) => {
        state.loading = false;
    },
    DO_SEARCH: (state) => {
        state.isSearching = !state.isSearching;
    },
    SET_SEARCH: (state, data) => {
        const dataLayer = window.dataLayer || [];
        dataLayer.push({
            event: 'kwastSearch',
            eventModel: {
                searchQuery: data,
            },
        });

        state.searchString = data;
    },
};
