import { Module } from 'vuex';
import { WineState, RootState } from '@/store/types';
import { actions } from '@/store/wines/actions';
import { getters } from '@/store/wines/getters';
import { mutations } from '@/store/wines/mutations';

export const state: WineState = {
    filter: '',
    assortmentFilter: '',
    favoritesFilter: '',
    order: '',
};

const namespaced = false;

export const wines: Module<WineState, RootState> = {
    namespaced,
    state,
    getters,
    actions,
    mutations,
};
