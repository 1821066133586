import { Module } from 'vuex';
import { actions } from '@/store/orders/actions';
import { getters } from '@/store/orders/getters';
import { mutations } from '@/store/orders/mutations';
import { OrderState, RootState } from '@/store/types';

export const state: OrderState = {
    version: '1.0',
};

const namespaced = false;

export const orders: Module<OrderState, RootState> = {
    namespaced,
    state,
    getters,
    actions,
    mutations,
};
