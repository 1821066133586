import axios from 'axios';
import { ActionTree } from 'vuex';
import { OrderState, RootState } from '../types';

export const actions: ActionTree<OrderState, RootState> = {
    GET_ORDERS({ commit }) {
        commit('LOADING_START');

        return new Promise((resolve, reject) => {
            axios.get(`${process.env.VUE_APP_API_URL}bestelsite/orders`)
                .then((resp: any) => {
                    commit('LOADING_STOP');

                    resolve(resp);
                })
                .catch((err) => {
                    commit('LOADING_STOP');

                    reject(err);
                });
        });
    },
    GET_RESERVATIONS({ commit }) {
        commit('LOADING_START');

        return new Promise((resolve, reject) => {
            axios.get(`${process.env.VUE_APP_API_URL}bestelsite/reservations`)
                .then((resp: any) => {
                    commit('LOADING_STOP');

                    resolve(resp);
                })
                .catch((err) => {
                    commit('LOADING_STOP');

                    reject(err);
                });
        });
    },
};
