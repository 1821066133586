import { createElementVNode as _createElementVNode, resolveComponent as _resolveComponent, createVNode as _createVNode, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, createBlock as _createBlock, withCtx as _withCtx, Teleport as _Teleport } from "vue"

const _hoisted_1 = {
  id: "nav",
  class: "container"
}
const _hoisted_2 = { class: "d-flex justify-content-between" }
const _hoisted_3 = { class: "nav-main" }
const _hoisted_4 = {
  key: 0,
  class: "d-flex flex-row h-100 list-unstyled"
}
const _hoisted_5 = { class: "d-flex align-items-center" }
const _hoisted_6 = { class: "list-inline h-100" }
const _hoisted_7 = { class: "d-flex flex-column justify-content-center align-items-center" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_NavItem = _resolveComponent("NavItem")!
  const _component_Button = _resolveComponent("Button")!
  const _component_SearchIcon = _resolveComponent("SearchIcon")!
  const _component_TextInput = _resolveComponent("TextInput")!
  const _component_Modal = _resolveComponent("Modal")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createElementVNode("div", _hoisted_2, [
      _cache[3] || (_cache[3] = _createElementVNode("div", null, [
        _createElementVNode("a", { href: "/" }, [
          _createElementVNode("img", {
            class: "logo",
            src: "/images/logo.svg"
          })
        ])
      ], -1)),
      _createElementVNode("div", _hoisted_3, [
        ($props.show)
          ? (_openBlock(), _createElementBlock("ul", _hoisted_4, [
              _createVNode(_component_NavItem, {
                label: "Assortiment",
                menu: $setup.assortMenu,
                menuType: "big",
                active: $setup.routeName === 'Assortiment' || $setup.routeName === 'Zalto' || $setup.routeName === 'Stoppers',
                onMenuItemClicked: $setup.callback
              }, null, 8, ["menu", "active", "onMenuItemClicked"]),
              _createVNode(_component_NavItem, {
                label: "Acties",
                styling: "red",
                active: $setup.routeName === 'Actions',
                onNavClick: _cache[0] || (_cache[0] = ($event: any) => ($setup.navigateTo('Actions')))
              }, null, 8, ["active"]),
              _createElementVNode("li", _hoisted_5, [
                _createVNode(_component_Button, {
                  icon: "search",
                  text: "Zoeken",
                  onButtonClick: $setup.openSearch
                }, null, 8, ["onButtonClick"])
              ]),
              _createVNode(_component_NavItem, {
                img: "account",
                label: "Account",
                menu: $setup.accountMenu,
                active: $setup.routeName === 'Account' || $setup.routeName === 'Orders' || $setup.routeName === 'Invoices',
                routeName: $setup.routeName,
                onMenuItemClicked: $setup.callback
              }, null, 8, ["menu", "active", "routeName", "onMenuItemClicked"]),
              _createVNode(_component_NavItem, {
                img: "heart-outline",
                label: "Mijn wijnen",
                menu: $setup.wineMenu,
                active: $setup.routeName === 'Favorites' || $setup.routeName === 'OrderedBefore',
                routeName: $setup.routeName,
                onMenuItemClicked: $setup.callback
              }, null, 8, ["menu", "active", "routeName", "onMenuItemClicked"])
            ]))
          : _createCommentVNode("", true)
      ]),
      _createElementVNode("div", null, [
        _createElementVNode("ul", _hoisted_6, [
          ($props.show)
            ? (_openBlock(), _createBlock(_component_NavItem, {
                key: 0,
                img: $setup.cartCount() >= 1 ? 'winkelmand_filled' : 'winkelmand_empty',
                label: "Winkelmand",
                count: $setup.cartCount(),
                active: $setup.routeName === 'Cart',
                onClick: _cache[1] || (_cache[1] = ($event: any) => ($setup.navigateTo('Cart')))
              }, null, 8, ["img", "count", "active"]))
            : _createCommentVNode("", true)
        ])
      ])
    ]),
    ($setup.showModal)
      ? (_openBlock(), _createBlock(_Teleport, {
          key: 0,
          to: "#modal"
        }, [
          _createVNode(_component_Modal, null, {
            body: _withCtx(() => [
              _createElementVNode("div", _hoisted_7, [
                _cache[4] || (_cache[4] = _createElementVNode("h1", { class: "red" }, " Zoeken? ", -1)),
                _createVNode(_component_TextInput, {
                  ref: "searchInput",
                  modelValue: $setup.searchString,
                  "onUpdate:modelValue": _cache[2] || (_cache[2] = ($event: any) => (($setup.searchString) = $event)),
                  type: "text",
                  onSubmit: $setup.search,
                  onIconRightClick: $setup.search
                }, {
                  "icon-right": _withCtx(() => [
                    _createVNode(_component_SearchIcon, { class: "icon" })
                  ]),
                  _: 1
                }, 8, ["modelValue", "onSubmit", "onIconRightClick"])
              ])
            ]),
            backdrop: _withCtx(() => _cache[5] || (_cache[5] = [
              _createElementVNode("div", { class: "modal-backdrop show" }, null, -1)
            ])),
            _: 1
          })
        ]))
      : _createCommentVNode("", true)
  ]))
}