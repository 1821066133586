<template>
    <div class="input-container">
        <label v-if="label">
            {{ label }}
            <span v-if="formRequired">
                *
            </span>
        </label>
        <div class="input-wrapper">
            <span>
                <slot name="icon-left"/>
            </span>
            <input
                :class="{
                    'has-icon' : hasSlot('icon-right'),
                    'has-errors' : hasErrors,
                }"
                :value="modelValue"
                :type="type"
                @input="emitValue($event.target.value)"
                @blur="emitBlur"
                @keydown.enter.prevent="emitSubmit"
            />
            <span v-if="hasSlot('icon-right')" class="icon-box right" @click="$emit('iconRightClick', $event)">
                <slot name="icon-right"/>
            </span>
        </div>

        <p v-if="hasErrors && error" class="error">
            {{ error }}
        </p>
    </div>
</template>

<script type="ts">

export default {
    props: {
        hasErrors: {
            default: false,
        },
        error: {
            type: String,
            required: false,
        },
        type: {
            type: String,
            default: 'text',
            validator(value) {
                return ['text', 'number', 'email', 'password', 'file'].indexOf(value) !== -1;
            },
        },
        label: {
            type: String,
        },
        formRequired: {
            type: Boolean,
            default: false,
        },
        modelValue: String,
    },
    setup(props, context) {
        function emitValue(newValue) {
            context.emit('update:modelValue', newValue);
        }

        function emitBlur() {
            context.emit('blur');
        }

        function emitSubmit() {
            context.emit('submit');
        }

        function hasSlot(slot) {
            return !!context.slots[slot];
        }

        return {
            emitValue,
            emitBlur,
            emitSubmit,
            hasSlot,
        };
    },
};
</script>

<style scoped lang="scss">
@import "~@/assets/variables";

.input-container {
    display: flex;
    flex-direction: column;

    label {
        text-align: left;
        width: 100%;
        height: 26px;
        margin: 8px 0;
        font-family: 'DINPro';
        font-size: 16px;
        font-weight: 300;
        font-stretch: normal;
        font-style: normal;
        line-height: 1.63;
        letter-spacing: normal;
        color: #000000;
    }

    .input-wrapper {
        position: relative;

        input {
            width: 100%;
            height: 40px;
            margin-bottom: 20px;
            background-color: #f2f2f2;
            border: none;
            padding-left: .75rem;
            padding-right: .75rem;
            &:focus {
                outline: none;
            }

            &.has-errors {
                border: 3px solid $kwast-red;
                color: $kwast-red;
                background: $kwast-lightred;
                margin-bottom: 10px;
            }

            &.has-icon {
                padding-right: 3rem;
            }
        }

        .icon-box {
            display: flex;
            align-items: center;
            position: absolute;
            top: 0;
            bottom: 0;
            height: 40px;
            width: 40px;
            cursor: pointer;

            svg {
                width: 50%;
            }

            &.right {
                right: 0;
                color: $black;
            }
        }
    }

    .error {
        text-align: left;
        color: $kwast-red;
        font-size: 1rem;
        max-width: 300px;
    }
}
</style>
