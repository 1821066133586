import { GetterTree } from 'vuex';
import { WineState, RootState } from '@/store/types';

export const getters: GetterTree<WineState, RootState> = {
    filter(state): string {
        return state.filter;
    },
    order(state): string {
        return state.order;
    },
};
