import { GetterTree } from 'vuex';
import { AuthState, RootState } from '../types';
import { User } from '@/shared/models/User';

export const getters: GetterTree<AuthState, RootState> = {
    isAuthenticated(state): boolean {
        return !!state.token;
    },

    authStatus(state): string {
        return state.status;
    },

    authUser(state): User|undefined {
        return state.user;
    },

    authToken(state): string {
        return state.token;
    },

};
