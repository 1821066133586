
import {
    computed, ref, watch,
} from 'vue';
import { useRoute, useRouter } from 'vue-router';
import { useStore } from 'vuex';
import SearchIcon from '@/assets/icons/search-outline.svg';
import NavItem from '@/components/NavItem.vue';
import Button from '@/shared/components/Button.vue';
import TextInput from '@/shared/components/form/TextInput.vue';
import Modal from '@/views/Modal.vue';

export default {
    components: {
        TextInput,
        Button,
        NavItem,
        Modal,
    },
    props: {
        show: {
            type: Boolean,
        },
    },
    setup(props: any, context: any) {
        const searchInput: any = ref(null);
        const store = useStore();
        const router = useRouter();
        const showModal = computed(() => store.getters.showModal('search'));
        const cartCount = computed(() => store.getters.cartCount);
        const searchString = ref(store.getters.getSearch());

        function logout() {
            store.dispatch('AUTH_LOGOUT').then(() => router.push({ name: 'Login' }));
        }

        function navigateTo(url: string, query?: any) {
            window.scrollTo(0, 0);
            router.push({ name: url, query });
        }

        const accountMenu = [
            {
                img: 'account',
                label: 'Accountoverzicht',
                routeName: 'Account',
                callBack: () => navigateTo('Account'),
            },
            {
                img: 'orders',
                label: 'Bestellingen',
                routeName: 'Orders',
                callBack: () => navigateTo('Orders'),
            },
            {
                img: 'invoices',
                label: 'Facturen',
                routeName: 'Invoices',
                callBack: () => navigateTo('Invoices'),
            },
            {
                img: 'logout',
                label: 'Uitloggen',
                callBack: logout,
            },
        ];

        const wineMenu = [
            {
                img: 'ordered-before',
                label: 'Eerder besteld',
                routeName: 'OrderedBefore',
                callBack: () => navigateTo('OrderedBefore'),
            },
            {
                img: 'heart-outline',
                label: 'Favorieten',
                routeName: 'Favorites',
                callBack: () => navigateTo('Favorites'),
            },
        ];

        const assortMenu = [
            {
                label: 'ALLE WIJNLANDEN',
                callBack: () => navigateTo('Assortiment'),
            },
            {
                label: 'Frankrijk',
                callBack: () => navigateTo('Assortiment', { land: 'frankrijk' }),
            },
            {
                label: 'Portugal',
                callBack: () => navigateTo('Assortiment', { land: 'portugal' }),
            },
            {
                label: 'Spanje',
                callBack: () => navigateTo('Assortiment', { land: 'spanje' }),
            },
            {
                label: 'Italië',
                callBack: () => navigateTo('Assortiment', { land: 'italië' }),
            },
            {
                label: 'Luxemburg',
                callBack: () => navigateTo('Assortiment', { land: 'luxemburg' }),
            },
            {
                label: 'Duitsland',
                callBack: () => navigateTo('Assortiment', { land: 'duitsland' }),
            },
            {
                label: 'Oostenrijk',
                callBack: () => navigateTo('Assortiment', { land: 'oostenrijk' }),
            },
            {
                label: 'Verenigde Staten',
                callBack: () => navigateTo('Assortiment', { land: 'verenigde staten' }),
            },
            {
                label: 'Chili',
                callBack: () => navigateTo('Assortiment', { land: 'chili' }),
            },
            {
                label: 'Argentinië',
                callBack: () => navigateTo('Assortiment', { land: 'argentinië' }),
            },
            {
                label: 'Nieuw-Zeeland',
                callBack: () => navigateTo('Assortiment', { land: 'nieuw-zeeland' }),
            },
            {
                label: 'Australië',
                callBack: () => navigateTo('Assortiment', { land: 'australië' }),
            },
            {
                label: 'Zuid-Afrika',
                callBack: () => navigateTo('Assortiment', { land: 'zuid-afrika' }),
            },
            {
                label: 'WIJNSTOPPER',
                callBack: () => navigateTo('Stoppers'),
            },
            {
                label: 'ZALTO GLAS',
                callBack: () => navigateTo('Zalto'),
            },
        ];

        const route = useRoute();
        const routeName = ref(route.name);

        watch(route, () => {
            routeName.value = route.name;
        });

        function callback(val: any) {
            if (val.callBack) {
                val.callBack();
            }
        }

        function openSearch() {
            store.dispatch('MODAL_SHOW', 'search').then((res) => {
                searchString.value = '';

                if (searchInput && searchInput.value !== null && searchInput.value.$el) {
                    const input = searchInput.value.$el.firstElementChild?.children?.item(1);
                    if (input && input instanceof HTMLElement) {
                        input.focus();
                    }
                }
            });
        }

        function search() {
            store.dispatch('MODAL_HIDE');
            store.dispatch('SET_SEARCH', searchString.value);
            navigateTo('Assortiment', { search: searchString.value });
            store.dispatch('DO_SEARCH');
        }

        return {
            accountMenu,
            wineMenu,
            assortMenu,
            showAccount: ref(false),
            showOther: ref(false),
            callback,
            openSearch,
            search,
            showModal,
            navigateTo,
            SearchIcon,
            searchString,
            cartCount,
            routeName,
            searchInput,
        };
    },
};
