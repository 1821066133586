import axios from 'axios';
import { ActionTree } from 'vuex';
import { InvoiceState, RootState } from '../types';

export const actions: ActionTree<InvoiceState, RootState> = {
    GET_INVOICES({ commit }) {
        commit('LOADING_START');

        return new Promise((resolve, reject) => {
            axios.get(`${process.env.VUE_APP_API_URL}bestelsite/invoices`)
                .then((resp: any) => {
                    commit('LOADING_STOP');

                    resolve(resp);
                })
                .catch((err) => {
                    commit('LOADING_STOP');

                    reject(err);
                });
        });
    },
    DOWNLOAD_INVOICE({ commit }, no: string) {
        commit('LOADING_START');

        return new Promise((resolve, reject) => {
            axios.get(`${process.env.VUE_APP_API_URL}bestelsite/invoices/${no}/download`, {
                responseType: 'blob',
            })
                .then((resp: any) => {
                    commit('LOADING_STOP');

                    resolve(resp);
                })
                .catch((err) => {
                    commit('LOADING_STOP');

                    reject(err);
                });
        });
    },
};
