import { MutationTree } from 'vuex';
import { AuthState } from '../types';
import injector from '@/helpers/httpInterceptor';

export const mutations: MutationTree<AuthState> = {
    AUTH_REQUEST(state) {
        state.status = 'loading';
    },
    AUTH_SUCCESS: (state, token) => {
        state.status = 'success';
        state.token = token;
        injector();
    },
    AUTH_ERROR: (state) => {
        state.status = 'error';
    },
    AUTH_LOGOUT: (state) => {
        state.token = '';
        state.user = undefined;
    },
    AUTH_USER: (state, data) => {
        state.user = data;
    },
};
