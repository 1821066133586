import { resolveDynamicComponent as _resolveDynamicComponent, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, toDisplayString as _toDisplayString, createElementBlock as _createElementBlock, createTextVNode as _createTextVNode, normalizeClass as _normalizeClass, createElementVNode as _createElementVNode, renderList as _renderList, Fragment as _Fragment, resolveDirective as _resolveDirective, withDirectives as _withDirectives } from "vue"

const _hoisted_1 = { class: "h-100" }
const _hoisted_2 = { class: "menu-item d-flex align-items-center position-relative" }
const _hoisted_3 = {
  key: 1,
  id: "count",
  class: "text-white position-absolute"
}
const _hoisted_4 = {
  key: 0,
  class: "list-unstyled position-absolute menu-dropdown clearfix"
}
const _hoisted_5 = ["onClick"]
const _hoisted_6 = {
  key: 1,
  class: "list-unstyled position-absolute menu-dropdown big d-flex flex-wrap justify-content-start"
}
const _hoisted_7 = ["onClick"]
const _hoisted_8 = ["src", "alt"]
const _hoisted_9 = { class: "menu-dropdown-link" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _directive_click_outside = _resolveDirective("click-outside")!

  return (_openBlock(), _createElementBlock("li", _hoisted_1, [
    _withDirectives((_openBlock(), _createElementBlock("div", {
      class: _normalizeClass(["menu-button h-100 d-flex align-items-center", [$setup.getButtonStyling()]]),
      onClick: _cache[0] || (_cache[0] = 
//@ts-ignore
(...args) => ($setup.navClick && $setup.navClick(...args)))
    }, [
      _createElementVNode("span", _hoisted_2, [
        ($props.img)
          ? (_openBlock(), _createBlock(_resolveDynamicComponent($setup.getIconByName($props.img)), {
              key: 0,
              class: "menu-logo"
            }))
          : _createCommentVNode("", true),
        ($props.count)
          ? (_openBlock(), _createElementBlock("span", _hoisted_3, _toDisplayString($props.count.toLocaleString('nl-NL', {
                        minimumIntegerDigits: 2,
                        useGrouping: false,
                    })), 1))
          : _createCommentVNode("", true),
        _createElementVNode("span", {
          class: _normalizeClass(["menu-link", $props.active ? 'red' : ''])
        }, [
          _createTextVNode(_toDisplayString($props.label) + " ", 1),
          ($props.menu)
            ? (_openBlock(), _createBlock(_resolveDynamicComponent($setup.show ? $setup.ChevronUp : $setup.ChevronDown), {
                key: 0,
                class: "chevron"
              }))
            : _createCommentVNode("", true)
        ], 2)
      ]),
      ($props.menu && $setup.show && $props.menuType === 'small')
        ? (_openBlock(), _createElementBlock("ul", _hoisted_4, [
            (_openBlock(true), _createElementBlock(_Fragment, null, _renderList($props.menu, (menuItem, index) => {
              return (_openBlock(), _createElementBlock("li", {
                key: index,
                class: "d-flex align-items-center menu-dropdown-item",
                onClick: ($event: any) => ($setup.menuItemClicked(menuItem))
              }, [
                (menuItem.img)
                  ? (_openBlock(), _createBlock(_resolveDynamicComponent($setup.getIconByName(menuItem.img)), {
                      key: 0,
                      class: "menu-item-logo"
                    }))
                  : _createCommentVNode("", true),
                _createElementVNode("span", {
                  class: _normalizeClass(["menu-dropdown-link flex-shrink-0", $props.routeName === menuItem.routeName ? 'red' : ''])
                }, _toDisplayString(menuItem.label), 3)
              ], 8, _hoisted_5))
            }), 128))
          ]))
        : _createCommentVNode("", true),
      ($props.menu && $setup.show && $props.menuType === 'big')
        ? (_openBlock(), _createElementBlock("ul", _hoisted_6, [
            (_openBlock(true), _createElementBlock(_Fragment, null, _renderList($props.menu, (menuItem, index) => {
              return (_openBlock(), _createElementBlock("li", {
                key: index,
                class: "d-flex menu-dropdown-item",
                onClick: ($event: any) => ($setup.menuItemClicked(menuItem))
              }, [
                (menuItem.img)
                  ? (_openBlock(), _createElementBlock("img", {
                      key: 0,
                      class: "menu-item-logo",
                      src: require(`@/assets/icons/${menuItem.img}.svg`),
                      alt: menuItem.img
                    }, null, 8, _hoisted_8))
                  : _createCommentVNode("", true),
                _createElementVNode("span", _hoisted_9, _toDisplayString(menuItem.label), 1)
              ], 8, _hoisted_7))
            }), 128))
          ]))
        : _createCommentVNode("", true)
    ], 2)), [
      [_directive_click_outside, $setup.hideNav]
    ])
  ]))
}