import { createApp } from 'vue'
import App from './App.vue'
import router from './router'
import store from './store'
import axios from 'axios';
import VueAxios from 'vue-axios';
import 'bootstrap/dist/css/bootstrap.css';
import 'reflect-metadata';
import interceptor from './helpers/httpInterceptor';
import clickOutside from './helpers/click-outside';
interceptor();

const app = createApp(App);

app
    .directive('click-outside', clickOutside)
    .use(store)
    .use(router)
    .use(VueAxios, axios)
    .mount('#app');
