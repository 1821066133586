import { toDisplayString as _toDisplayString, resolveComponent as _resolveComponent, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, createTextVNode as _createTextVNode, createElementVNode as _createElementVNode, renderList as _renderList, Fragment as _Fragment, createElementBlock as _createElementBlock, normalizeClass as _normalizeClass, resolveDirective as _resolveDirective, withDirectives as _withDirectives } from "vue"

const _hoisted_1 = { class: "menu-item d-flex align-items-center" }
const _hoisted_2 = { class: "menu-link" }
const _hoisted_3 = {
  key: 0,
  class: "menu-dropdown"
}
const _hoisted_4 = ["onClick"]
const _hoisted_5 = { class: "menu-dropdown-link" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_ChevronForward = _resolveComponent("ChevronForward")!
  const _directive_click_outside = _resolveDirective("click-outside")!

  return (_openBlock(), _createElementBlock("div", null, [
    _withDirectives((_openBlock(), _createElementBlock("div", {
      class: _normalizeClass(["menu-button d-flex flex-column", $setup.getButtonStyling()]),
      onClick: _cache[0] || (_cache[0] = 
//@ts-ignore
(...args) => ($setup.navClick && $setup.navClick(...args)))
    }, [
      _createElementVNode("span", _hoisted_1, [
        _createElementVNode("span", _hoisted_2, [
          _createTextVNode(_toDisplayString($props.label) + " ", 1),
          ($props.menu)
            ? (_openBlock(), _createBlock(_component_ChevronForward, {
                key: 0,
                class: "icon align-text-top"
              }))
            : _createCommentVNode("", true)
        ])
      ]),
      ($props.menu && $setup.show)
        ? (_openBlock(), _createElementBlock("ul", _hoisted_3, [
            (_openBlock(true), _createElementBlock(_Fragment, null, _renderList($props.menu, (menuItem, index) => {
              return (_openBlock(), _createElementBlock("li", {
                key: index,
                class: _normalizeClass(["d-flex align-items-center menu-dropdown-item text-start", {[`hide-last-${$props.menu.length - index}`]: !!menuItem.nodot}]),
                onClick: ($event: any) => ($setup.menuItemClicked(menuItem))
              }, [
                _createElementVNode("span", _hoisted_5, _toDisplayString(menuItem.label), 1)
              ], 10, _hoisted_4))
            }), 128))
          ]))
        : _createCommentVNode("", true)
    ], 2)), [
      [_directive_click_outside, $setup.hideNav]
    ])
  ]))
}