import Vuex, { StoreOptions } from 'vuex';
import { auth } from './auth';
import { cart } from '@/store/cart';
import { invoices } from '@/store/invoices';
import { modal } from '@/store/modal';
import { orders } from '@/store/orders';
import { RootState } from '@/store/types';
import { wines } from '@/store/wines';

const store: StoreOptions<RootState> = {
    modules: {
        auth,
        modal,
        wines,
        invoices,
        orders,
        cart,
    },
};

export default new Vuex.Store<RootState>(store);
