

import AccountIcon from '@/assets/icons/account.svg';
import HeartIcon from '@/assets/icons/heart-outline.svg';
import InvoicesIcon from '@/assets/icons/invoices.svg';
import LogoutIcon from '@/assets/icons/logout.svg';
import OrderedBeforeIcon from '@/assets/icons/ordered-before.svg';
import OrdersIcon from '@/assets/icons/orders.svg';
import SearchIcon from '@/assets/icons/search-outline.svg';
import ShoppingCartIcon from '@/assets/icons/winkelmand_empty.svg';

export default {
    props: {
        design: {
            default: '',
            type: String,
            required: false,
        },
        classExpression: {
            default: () => ({}),
            required: false,
        },
        type: {
            type: String,
            required: false,
        },
        disabled: {
            type: Boolean,
            required: false,
        },
        text: {
            type: String,
        },
        icon: {
            type: String,
            default: '',
        },
    },
    setup(props: any, context: any) {
        function buttonClick(e: any) {
            context.emit('buttonClick', e);
        }

        const icons = [
            {
                name: 'account',
                component: AccountIcon,
            },
            {
                name: 'winkelmand_empty',
                component: ShoppingCartIcon,
            },
            {
                name: 'heart-outline',
                component: HeartIcon,
            },
            {
                name: 'ordered-before',
                component: OrderedBeforeIcon,
            },
            {
                name: 'invoices',
                component: InvoicesIcon,
            },
            {
                name: 'logout',
                component: LogoutIcon,
            },
            {
                name: 'orders',
                component: OrdersIcon,
            },
            {
                name: 'search',
                component: SearchIcon,
            },
        ];

        function getIconByName(name: string) {
            return icons.find((icon: any) => icon.name === name)?.component;
        }

        return {
            getIconByName,
            buttonClick,
        };
    },
};
