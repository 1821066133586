
import { ref } from 'vue';
import { useStore } from 'vuex';

export default {
    props: {
        fullscreen: {
            type: Boolean,
            default: false,
        },
    },
    setup(props: any, context: any) {
        const fullScreen = ref(props.fullscreen);
        const store = useStore();

        function hideModal() {
            store.dispatch('MODAL_HIDE');
            context.emit('hide');
        }

        return {
            hideModal,
            fullScreen,
        };
    },
};
