import axios from 'axios';
import { plainToClass } from 'class-transformer';
import { ActionTree } from 'vuex';
import { AuthState, RootState } from '../types';
import { wrap } from '@/helpers/objectWrapper';
import { User } from '@/shared/models/User';

export const actions: ActionTree<AuthState, RootState> = {
    REGISTER({ commit }, formData) {
        commit('LOADING_START');

        return new Promise((resolve, reject) => {
            commit('AUTH_REQUEST');

            axios.post(`${process.env.VUE_APP_API_URL_V2}contacts`, wrap(formData))
                .then((resp: any) => {
                    commit('LOADING_STOP');

                    resolve(resp);
                })
                .catch((err) => {
                    commit('LOADING_STOP');
                    reject(err);
                });
        });
    },
    LOGIN({ commit, dispatch }, formData) {
        commit('LOADING_START');

        return new Promise((resolve, reject) => {
            commit('AUTH_REQUEST');

            axios.post(`${process.env.VUE_APP_API_URL_V2}authenticate`, formData)
                .then((resp: any) => {
                    const token = resp.data.data.attributes.token;
                    localStorage.setItem('user-token', token);

                    const attributes = resp.data.data.attributes;
                    const user = plainToClass(User, attributes);
                    localStorage.setItem('auth-user', JSON.stringify(user));

                    commit('LOADING_STOP');
                    commit('AUTH_USER', user);
                    commit('AUTH_SUCCESS', token);
                    resolve(resp);
                })
                .catch((err) => {
                    commit('LOADING_STOP');
                    commit('AUTH_ERROR', err);
                    dispatch('AUTH_LOGOUT');
                    reject(err);
                });
        });
    },
    LOGIN_WITH_TOKEN({ commit, dispatch }, formData) {
        commit('LOADING_START');

        return new Promise((resolve, reject) => {
            commit('AUTH_REQUEST');

            axios.post(`${process.env.VUE_APP_API_URL_V2}authenticate-with-token`, formData)
                .then((resp: any) => {
                    const token = resp.data.data.attributes.token;
                    localStorage.setItem('user-token', token);

                    const attributes = resp.data.data.attributes;
                    const user = plainToClass(User, attributes);
                    localStorage.setItem('auth-user', JSON.stringify(user));

                    commit('LOADING_STOP');
                    commit('AUTH_USER', user);
                    commit('AUTH_SUCCESS', token);
                    resolve(resp);
                })
                .catch((err) => {
                    commit('LOADING_STOP');
                    commit('AUTH_ERROR', err);
                    dispatch('AUTH_LOGOUT');
                    reject(err);
                });
        });
    },
    AUTH_LOGOUT({ commit }) {
        return new Promise((resolve) => {
            commit('AUTH_LOGOUT');
            localStorage.removeItem('user-token');
            localStorage.removeItem('auth-user');
            resolve(true);
        });
    },
    AUTH_USER({ commit }, user) {
        return new Promise((resolve) => {
            commit('AUTH_USER', user);
            localStorage.setItem('auth-user', JSON.stringify(user));
            resolve(true);
        });
    },
    FORGOT_PASSWORD({ commit }, formData) {
        commit('LOADING_START');

        return new Promise((resolve, reject) => {
            axios.post(`${process.env.VUE_APP_API_URL_V2}password/email`, formData)
                .then((resp: any) => {
                    commit('LOADING_STOP');
                    resolve(resp);
                })
                .catch((err) => {
                    commit('LOADING_STOP');
                    commit('AUTH_ERROR', err);
                    reject(err);
                });
        });
    },
    RESET_PASSWORD({ commit }, formData) {
        commit('LOADING_START');

        return new Promise((resolve, reject) => {
            axios.post(`${process.env.VUE_APP_API_URL_V2}password/reset`, formData)
                .then((resp: any) => {
                    commit('LOADING_STOP');
                    resolve(resp);
                })
                .catch((err) => {
                    commit('LOADING_STOP');
                    commit('AUTH_ERROR', err);
                    reject(err);
                });
        });
    },
    AUTH_INFO({ commit }) {
        return new Promise((resolve, reject) => {
            axios.get(`${process.env.VUE_APP_API_URL}bestelsite/account`)
                .then((resp: any) => {
                    resolve(resp);
                })
                .catch((err) => {
                    commit('AUTH_ERROR', err);
                    reject(err);
                });
        });
    },
    AUTH_CHANGE_PASSWORD({ commit }, formData) {
        commit('LOADING_START');

        return new Promise((resolve, reject) => {
            axios.put(`${process.env.VUE_APP_API_URL}bestelsite/account/password`, formData)
                .then((resp: any) => {
                    commit('LOADING_STOP');
                    resolve(resp);
                })
                .catch((err) => {
                    commit('LOADING_STOP');
                    commit('AUTH_ERROR', err);
                    reject(err);
                });
        });
    },
};
