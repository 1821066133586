import { MutationTree } from 'vuex';
import { WineState } from '../types';

export const mutations: MutationTree<WineState> = {
    SET_FILTER: (state, data) => {
        state.filter = data;
    },
    SET_ASSORTMENT_FILTER: (state, data) => {
        state.assortmentFilter = data;
    },
    SET_FAVORITES_FILTER: (state, data) => {
        state.favoritesFilter = data;
    },
    SET_ORDER: (state, data) => {
        state.order = data;
    },
};
