import { Module } from 'vuex';
import { actions } from '@/store/cart/actions';
import { getters } from '@/store/cart/getters';
import { mutations } from '@/store/cart/mutations';
import { CartState, RootState } from '@/store/types';

export const state: CartState = {
    version: '1.0',
    count: 0,
};

const namespaced = false;

export const cart: Module<CartState, RootState> = {
    namespaced,
    state,
    getters,
    actions,
    mutations,
};
