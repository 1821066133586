import { Module } from 'vuex';
import { actions } from '@/store/invoices/actions';
import { getters } from '@/store/invoices/getters';
import { mutations } from '@/store/invoices/mutations';
import { InvoiceState, RootState } from '@/store/types';

export const state: InvoiceState = {
    version: '1.0',
};

const namespaced = false;

export const invoices: Module<InvoiceState, RootState> = {
    namespaced,
    state,
    getters,
    actions,
    mutations,
};
