
import { watch } from 'vue';
import { useRouter } from 'vue-router';
import Button from '@/shared/components/Button.vue';
import store from '@/store';

export default {
    components: {
        Button,
    },
    setup() {
        function openMapsUrl() {
            window.open('https://goo.gl/maps/MTMdk8k5iygEgSqN6', '_blank');
        }

        function openContactUrl() {
            window.open('https://www.kwastwijnkopers.nl/contact/', '_blank');
        }

        const router = useRouter();
        function navigateHome() {
            window.scrollTo(0, 0);
            router.push({ name: 'Assortiment' });
        }

        watch(() => store.getters.isAuthenticated, (isAuthenticated) => {
            if (isAuthenticated) {
                const typebotInitScript = document.createElement('script');
                typebotInitScript.id = 'typebot';
                typebotInitScript.type = 'module';
                typebotInitScript.innerHTML = `import Typebot from 'https://cdn.jsdelivr.net/npm/@typebot.io/js@0.2.41/dist/web.js'

Typebot.initBubble({
    typebot: "kwast-wijnkopers-website",
    apiHost: "https://tbview.sevenlab.nl",
    previewMessage: {
        message: "Hulp nodig bij het kiezen van een wijn? Vraag het aan onze AI wijn sommelier!",
        autoShowDelay: 2000,
        avatarUrl: "https://www.kwastwijnkopers.nl/wp-content/themes/b4st-master/theme/images/grapes.svg",
    },
    theme: {
        button: { backgroundColor: "#aa0132" },
        chatWindow: { backgroundColor: "#fff" },
    },
});
`;
                document.body.append(typebotInitScript);

                return;
            }

            // Remove Typebot script
            const typebotInitScript = document.getElementById('typebot');
            if (typebotInitScript) {
                typebotInitScript.remove();
            }

            // Remove Typebot bubble
            const typebots = document.getElementsByTagName('typebot-bubble');
            if (typebots.length > 0) {
                // eslint-disable-next-line no-plusplus
                for (let i = 0; i < typebots.length; i++) {
                    typebots[i].remove();
                }
            }
        }, { immediate: true });

        return {
            openMapsUrl,
            openContactUrl,
            navigateHome,
        };
    },
};
