import { resolveComponent as _resolveComponent, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, createVNode as _createVNode, createElementVNode as _createElementVNode, createElementBlock as _createElementBlock, withCtx as _withCtx, Teleport as _Teleport, normalizeClass as _normalizeClass } from "vue"

const _hoisted_1 = { class: "d-flex flex-row h-100 justify-content-center align-items-center" }
const _hoisted_2 = {
  xmlns: "http://www.w3.org/2000/svg",
  "xmlns:xlink": "http://www.w3.org/1999/xlink",
  style: {"margin":"auto","background":"transparent","display":"block","shape-rendering":"auto"},
  width: "100px",
  height: "100px",
  viewBox: "0 0 100 100",
  preserveAspectRatio: "xMidYMid"
}

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_Navbar = _resolveComponent("Navbar")!
  const _component_NavbarMobile = _resolveComponent("NavbarMobile")!
  const _component_router_view = _resolveComponent("router-view")!
  const _component_Footer = _resolveComponent("Footer")!
  const _component_Modal = _resolveComponent("Modal")!

  return (_openBlock(), _createElementBlock("div", {
    class: _normalizeClass({'no-scroll': ($setup.width < 1200 && $setup.showNavMobile) || ($setup.width < 1200 && $setup.showFilter) } )
  }, [
    ($setup.width >= 1200)
      ? (_openBlock(), _createBlock(_component_Navbar, {
          key: "navbar-main",
          show: $setup.isAuthenticated
        }, null, 8, ["show"]))
      : _createCommentVNode("", true),
    ($setup.width < 1200)
      ? (_openBlock(), _createBlock(_component_NavbarMobile, {
          key: "navbar-mobile",
          show: $setup.isAuthenticated
        }, null, 8, ["show"]))
      : _createCommentVNode("", true),
    _createVNode(_component_router_view),
    _createVNode(_component_Footer),
    ($setup.loading)
      ? (_openBlock(), _createBlock(_Teleport, {
          key: 2,
          to: "#modal"
        }, [
          _createVNode(_component_Modal, {
            fullscreen: true,
            style: {"z-index":"99"}
          }, {
            body: _withCtx(() => [
              _createElementVNode("div", _hoisted_1, [
                (_openBlock(), _createElementBlock("svg", _hoisted_2, _cache[0] || (_cache[0] = [
                  _createElementVNode("g", { transform: "translate(20 50)" }, [
                    _createElementVNode("circle", {
                      cx: "0",
                      cy: "0",
                      r: "6",
                      fill: "#aa0132"
                    }, [
                      _createElementVNode("animateTransform", {
                        attributeName: "transform",
                        type: "scale",
                        begin: "-0.375s",
                        calcMode: "spline",
                        keySplines: "0.3 0 0.7 1;0.3 0 0.7 1",
                        values: "0;1;0",
                        keyTimes: "0;0.5;1",
                        dur: "1s",
                        repeatCount: "indefinite"
                      })
                    ])
                  ], -1),
                  _createElementVNode("g", { transform: "translate(40 50)" }, [
                    _createElementVNode("circle", {
                      cx: "0",
                      cy: "0",
                      r: "6",
                      fill: "#aa0132"
                    }, [
                      _createElementVNode("animateTransform", {
                        attributeName: "transform",
                        type: "scale",
                        begin: "-0.25s",
                        calcMode: "spline",
                        keySplines: "0.3 0 0.7 1;0.3 0 0.7 1",
                        values: "0;1;0",
                        keyTimes: "0;0.5;1",
                        dur: "1s",
                        repeatCount: "indefinite"
                      })
                    ])
                  ], -1),
                  _createElementVNode("g", { transform: "translate(60 50)" }, [
                    _createElementVNode("circle", {
                      cx: "0",
                      cy: "0",
                      r: "6",
                      fill: "#aa0132"
                    }, [
                      _createElementVNode("animateTransform", {
                        attributeName: "transform",
                        type: "scale",
                        begin: "-0.125s",
                        calcMode: "spline",
                        keySplines: "0.3 0 0.7 1;0.3 0 0.7 1",
                        values: "0;1;0",
                        keyTimes: "0;0.5;1",
                        dur: "1s",
                        repeatCount: "indefinite"
                      })
                    ])
                  ], -1),
                  _createElementVNode("g", { transform: "translate(80 50)" }, [
                    _createElementVNode("circle", {
                      cx: "0",
                      cy: "0",
                      r: "6",
                      fill: "#aa0132"
                    }, [
                      _createElementVNode("animateTransform", {
                        attributeName: "transform",
                        type: "scale",
                        begin: "0s",
                        calcMode: "spline",
                        keySplines: "0.3 0 0.7 1;0.3 0 0.7 1",
                        values: "0;1;0",
                        keyTimes: "0;0.5;1",
                        dur: "1s",
                        repeatCount: "indefinite"
                      })
                    ])
                  ], -1)
                ])))
              ])
            ]),
            backdrop: _withCtx(() => _cache[1] || (_cache[1] = [
              _createElementVNode("div", { class: "modal-backdrop show" }, null, -1)
            ])),
            _: 1
          })
        ]))
      : _createCommentVNode("", true)
  ], 2))
}