import { renderSlot as _renderSlot, resolveDirective as _resolveDirective, openBlock as _openBlock, createElementBlock as _createElementBlock, withDirectives as _withDirectives, normalizeClass as _normalizeClass, createElementVNode as _createElementVNode } from "vue"

const _hoisted_1 = { id: "modal-view" }
const _hoisted_2 = {
  class: "modal",
  role: "dialog",
  tabindex: "-1"
}
const _hoisted_3 = { class: "modal-body" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _directive_click_outside = _resolveDirective("click-outside")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createElementVNode("div", _hoisted_2, [
      _createElementVNode("div", {
        class: _normalizeClass(["modal-dialog modal-dialog-centered", {'fullscreen': $setup.fullScreen}]),
        role: "document"
      }, [
        _createElementVNode("div", {
          class: _normalizeClass(["modal-content bg-transparent border-0", {'fullscreen-content': $setup.fullScreen}])
        }, [
          _withDirectives((_openBlock(), _createElementBlock("div", _hoisted_3, [
            _renderSlot(_ctx.$slots, "body", {}, undefined, true)
          ])), [
            [_directive_click_outside, $setup.hideModal]
          ])
        ], 2)
      ], 2)
    ]),
    _createElementVNode("div", {
      class: "backdrop",
      onClick: _cache[0] || (_cache[0] = ($event: any) => (_ctx.$emit('hide')))
    }, [
      _renderSlot(_ctx.$slots, "backdrop", {}, undefined, true)
    ])
  ]))
}