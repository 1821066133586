import { Module } from 'vuex';
import { actions } from '@/store/modal/actions';
import { getters } from '@/store/modal/getters';
import { mutations } from '@/store/modal/mutations';
import { ModalState, RootState } from '@/store/types';

export const state: ModalState = {
    show: '',
    showNavMobile: false,
    showFilter: false,
    loading: false,
    isSearching: false,
    searchString: '',
};

const namespaced = false;

export const modal: Module<ModalState, RootState> = {
    namespaced,
    state,
    getters,
    actions,
    mutations,
};
