import { toDisplayString as _toDisplayString, resolveDynamicComponent as _resolveDynamicComponent, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, createTextVNode as _createTextVNode, normalizeClass as _normalizeClass, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = ["type", "disabled"]

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  return (_openBlock(), _createElementBlock("button", {
    class: _normalizeClass(['button-' + $props.design, $props.classExpression]),
    type: $props.type,
    disabled: $props.disabled,
    onClick: _cache[0] || (_cache[0] = 
//@ts-ignore
(...args) => ($setup.buttonClick && $setup.buttonClick(...args)))
  }, [
    _createTextVNode(_toDisplayString($props.text) + " ", 1),
    ($props.icon)
      ? (_openBlock(), _createBlock(_resolveDynamicComponent($setup.getIconByName($props.icon)), {
          key: 0,
          class: "icon"
        }))
      : _createCommentVNode("", true)
  ], 10, _hoisted_1))
}