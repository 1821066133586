import { createRouter, createWebHistory, RouteRecordRaw } from 'vue-router';
import store from '@/store';

const ifAuthenticated = (to: any, from: any, next: any) => {
    if (store.getters.isAuthenticated) {
        next();
        return;
    }
    next('/login');
};

const ifNotAuthenticated = (to: any, from: any, next: any) => {
    if (store.getters.isAuthenticated) {
        if (from) {
            next(from);
        }

        next('/account');
        return;
    }

    next();
};

const routes: Array<RouteRecordRaw> = [
    {
        path: '/',
        redirect: '/favorieten',
    },
    {
        path: '/login',
        name: 'Login',
        component: () => import(/* webpackChunkName: "Login" */ '../views/Login.vue'),
        beforeEnter: ifNotAuthenticated,
    },
    {
        path: '/wachtwoord-vergeten',
        name: 'ForgotPassword',
        component: () => import(/* webpackChunkName: "ForgotPassword" */ '../views/ForgotPassword.vue'),
        beforeEnter: ifNotAuthenticated,
    },
    {
        path: '/wachtwoord/reset/:email/token/:token',
        name: 'ResetPassword',
        component: () => import(/* webpackChunkName: "ForgotPassword" */ '../views/ResetPassword.vue'),
        beforeEnter: ifNotAuthenticated,
    },
    {
        path: '/registreer',
        name: 'Register',
        component: () => import(/* webpackChunkName: "Register" */ '../views/Register.vue'),
        beforeEnter: ifNotAuthenticated,
    },
    {
        path: '/account',
        name: 'Account',
        component: () => import(/* webpackChunkName: "Account" */ '../views/Account.vue'),
        beforeEnter: ifAuthenticated,
    },
    {
        path: '/acties',
        name: 'Actions',
        component: () => import(/* webpackChunkName: "Actions" */ '../views/Actions.vue'),
        beforeEnter: ifAuthenticated,
    },
    {
        path: '/assortiment',
        name: 'Assortiment',
        component: () => import(/* webpackChunkName: "Assortiment" */ '../views/Assortiment.vue'),
        beforeEnter: ifAuthenticated,
    },
    {
        path: '/assortiment/zalto',
        name: 'Zalto',
        component: () => import(/* webpackChunkName: "Zalto" */ '../views/Zalto.vue'),
        beforeEnter: ifAuthenticated,
    },
    {
        path: '/assortiment/zalto/:id',
        name: 'ZaltoDetail',
        component: () => import(/* webpackChunkName: "ZaltoDetail" */ '../views/ZaltoDetail.vue'),
        beforeEnter: ifAuthenticated,
    },
    {
        path: '/assortiment/wijnstoppers',
        name: 'Stoppers',
        component: () => import(/* webpackChunkName: "Stoppers" */ '../views/Stoppers.vue'),
        beforeEnter: ifAuthenticated,
    },
    {
        path: '/assortiment/wijnstoppers/:id',
        name: 'StopperDetail',
        component: () => import(/* webpackChunkName: "StopperDetail" */ '../views/StopperDetail.vue'),
        beforeEnter: ifAuthenticated,
    },
    {
        path: '/assortiment/:id',
        name: 'AssortimentDetail',
        component: () => import(/* webpackChunkName: "WineDetail" */ '../views/WineDetail.vue'),
        beforeEnter: ifAuthenticated,
    },
    {
        path: '/favorieten',
        name: 'Favorites',
        component: () => import(/* webpackChunkName: "Favorites" */ '../views/Favorites.vue'),
        beforeEnter: ifAuthenticated,
    },
    {
        path: '/eerder-bestelde-wijnen',
        name: 'OrderedBefore',
        component: () => import(/* webpackChunkName: "OrderedBefore" */ '../views/OrderedBefore.vue'),
        beforeEnter: ifAuthenticated,
    },
    {
        path: '/facturen',
        name: 'Invoices',
        component: () => import(/* webpackChunkName: "Invoices" */ '../views/Invoices.vue'),
        beforeEnter: ifAuthenticated,
    },
    {
        path: '/bestellingen',
        name: 'Orders',
        component: () => import(/* webpackChunkName: "Orders" */ '../views/Orders.vue'),
        beforeEnter: ifAuthenticated,
    },
    {
        path: '/winkelmand',
        name: 'Cart',
        component: () => import(/* webpackChunkName: "Orders" */ '../views/Cart.vue'),
        beforeEnter: ifAuthenticated,
    },
    {
        path: '/:pathMatch(.*)*',
        name: '404',
        component: () => import(/* webpackChunkName: "404" */ '../views/404.vue'),
    },
];

const router = createRouter({
    history: createWebHistory(process.env.BASE_URL),
    routes,
});

router.beforeEach((to, from, next) => {
    const getPageCategory = (name: string) => {
        const winePages = [
            'Assortiment',
            'AssortimentDetail',
            'Zalto',
            'ZaltoDetail',
            'Stoppers',
            'StopperDetail',
        ];

        if (winePages.includes(name)) return 'wijn';

        return 'overige';
    };

    const dataLayer = window.dataLayer || [];
    dataLayer.push({
        event: 'virtualPageview',
        eventModel: {
            oldPageUrl: from.fullPath,
            newPageUrl: to.fullPath,
            pageCategory: getPageCategory(to.name),
        },
    });

    next();
});

export default router;
